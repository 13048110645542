<template>
  <div
    class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-4 lg:mt-9"
  >
    <div class="lg:flex">
      <div class="lg:w-2/3 mr-6">
        <div class="shadow-sm border border-gray-200 h-52 rounded-lg">
          <div class="h-9"></div>
          <div class="flex">
            <img
              class="w-32 h-40 transform transition duration-300 hover:scale-105 relative ml-9"
              :src="books.banner_desktop" alt=""
              
            />
            <p class="mt-12 text-lg font-bold ml-4"> {{ books.name }}</p>
          </div>
        </div>
       
      </div>
      <div class="lg:w-1/3 mt-4 lg:mt-0">
        <div class="h-12 shadow-sm border border-gray-200 rounded-lg flex items-center">
          
            <div class="ml-2">
              <p class=" lg:whitespace-nowrap">
                Nombre de lecture avec relecture
              </p>
            </div>
            <div class="ml-auto mr-12">
              <p class="">0</p>
            </div>
         
        </div>
        <div class="h-12 mt-2 shadow-sm border border-gray-200 rounded-lg flex items-center">
          
            <div class="ml-2">
              <p class=" lg:whitespace-nowrap">Nombre de lecture unique</p>
            </div>
            <div class="ml-auto mr-12">
              <p class="">{{ nbvuechapitre.length }}</p>
            </div>
          
        </div>
      
        <div
          class="shadow-sm border border-gray-200 h-12 mt-2 rounded-lg flex items-center"
        >
          <div class="ml-2">
            <p class="whitespace-nowrap">Temps moyen par chapitre lu</p>
          </div>
          <div class="ml-auto mr-12">
            <p class="text-center">0</p>
          </div>
        </div>
        <div
          class="shadow-sm border border-gray-200 h-12 mt-2 rounded-lg flex items-center"
        >
          <div class="ml-2">
            <p class="whitespace-nowrap">Nombre de chapitres publiés</p>
          </div>
          <div class="ml-auto mr-12">
            <p class="text-center">{{
                  books.chapters && books.chapters.length !== 0
                    ? books.chapters.length
                    : "0 "
                }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="shadow-sm border border-gray-200 lg:h-24 mt-4 rounded-lg">
      <div class="lg:flex">
        <div class="">
          <div class="flex lg:ml-32 ml-24 w-24">
            <p class="text-black mt-6 text-lg font-bold ml-4">{{ nbvuechapitre.length }}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mt-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
              />
            </svg>
          </div>
          <p class="lg:ml-24">Nombre de lecture</p>
        </div>
        <div class="">
          <div class="flex ml-24 w-24">
            <p class="mt-6 text-lg font-bold ml-4">{{commentaires.length}}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mt-6 ml-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </div>
          <p class="lg:ml-16">Avis sur l'histoire</p>
        </div>
        <div class="">
          <div class="flex ml-24 lg:ml-36 w-24">
            <p class="mt-6 text-lg font-bold ml-4">{{ books && books.moy_note ? books.moy_note.toFixed(1) : '0' }}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mt-6 ml-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              />
            </svg>
          </div>
          <p class="lg:ml-32">(note)</p>
        </div>
        <div class="">
          <div class="flex ml-24 w-24">
            <p class="mt-6 text-lg font-bold ml-4">{{Think.length}}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mt-6 ml-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </div>
          <p class="lg:ml-16">Nombre de réaction</p>
        </div>
        <div class="">
          <div class="flex ml-24 w-24">
            <p class="mt-6 text-lg font-bold ml-4">{{
                new Date(books.updated_at).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              }}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mt-6 ml-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </div>
          <p class="lg:ml-16">Date de publication</p>
        </div>
      </div>
    </div>
  </div>
  <DeleteModalFooter
    width="w-full md:w-2/3 lg:w-1/3"
    :is-open="showDeleteRepasModal"
    @cancel="showDeleteRepasModal = !showDeleteRepasModal"
    @delete="deleteRepas()"
  >
    <template #header>Supprimer</template>
    <template #body> Vous voulez suppprimer ce repas </template>
  </DeleteModalFooter>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRepas"
    @close-modal="showModalRepas = false"
  >
    <template #header> Ajouter un Repas</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="addRepas()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput id="nom" v-model="addform.name" class="mt-2" />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Prix" />
                  <div class="flex">
                    <BaseInput
                      id="prenom"
                      v-model="addform.prix"
                      class="mt-2"
                    />
                    <span class="mt-4 ml-1">FCFA</span>
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Description" />
                  <BaseInput
                    id="language"
                    v-model="addform.description"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Choisissez une Category" />
                  <select
                    name="category"
                    id="category"
                    v-model="addform.categoris_id"
                    class="block w-full p-2 border mt-2 border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                  >
                    <option
                      v-for="(category, index) in categorys"
                      :key="index"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                    <!-- Ajoutez plus d'options au besoin -->
                  </select>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Image" />
                  <BaseInput
                    id="image"
                    type="file"
                    @change="onFileChange"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter @cancel="showModalRepas = false" @send="addRepas()" />
    </template>
  </TheModal>
  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRepasUpdate"
    @close-modal="showModalRepasUpdate = false"
  >
    <template #header> Mettre à jour le Repas</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="addContact()">
        <div>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Nom " />
                  <BaseInput
                    id="nom"
                    v-model="addform.first_name"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-4">
                  <BaseLabel value="Prix" />
                  <BaseInput
                    id="prenom"
                    v-model="addform.last_name"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Type" />
                  <div class="relative mt-1">
                    <BaseInput v-model="phone" class="mt-2" />
                  </div>
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Description" />
                  <BaseInput
                    id="language"
                    v-model="addform.language"
                    class="mt-2"
                  />
                </div>
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Image" />
                  <BaseInput
                    id="language"
                    type="file"
                    v-model="addform.language"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <AddModalFooter
        @cancel="showModalRepasUpdate = false"
        @send="addContact()"
      />
    </template>
  </TheModal>
</template>

<script>
import axios from "axios";
import DeleteModalFooter from "../components/DeleteModalFooter.vue";
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import AddModalFooter from "../components/AddModalFooter.vue";
export default {
  name: "RepasDash",
  components: {
    DeleteModalFooter,
    TheModal,
    BaseLabel,
    BaseInput,
    AddModalFooter,
  },
  data() {
    return {
      addform: {
        categoris_id: "",
        name: "",
        description: "",
        prix: "",
        image_url: "",
      },
      alert: {
        type: "",
        message: "",
      },
      processing: false,
      showDeleteRepasModal: false,
      showModalRepas: false,
      showModalRepasUpdate: false,
      nbvuechapitre: [],
      books: [],
      user: "",
      filteredRestaurants: [],
      categorys: [],
      commentaires:[],
      Think:[],
    };
  },
   mounted() {
    this.romanID = this.$route.params.id;
  },
  computed: {},
  created() {
    this.profile();
     this.vuechapitre();
    this.book();
    this.commentaire();
    this.Getthink();

  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.filter = response.data.data.name;
          console.log(this.filter);
        }
      } catch (error) {
        console.error(error.response.data);
      }
    },
     async vuechapitre() {
      try {
        //const book_id = this.$route.params.id;

        const response = await axios.get(
          `/api/chapter-reads?book_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.nbvuechapitre = response.data.data;
          
          console.log(this.nbvuechapitre);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
   async book() {
      console.log(this.romanID);
      try {
        const response = await axios.get(`/api/books/${this.$route.params.id}`);
        if (response.data) {
          this.books = response.data.data;
          this.chapitres = this.books.chapters;
          this.usr_id = response.data.data.user.id;
          this.subgt(this.usr_id);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async commentaire() {
      try {
        //const book_id = this.$route.params.id;

        const response = await axios.get(
          `/api/comment?book_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.commentaires = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
      async Getthink() {
      try {
        //this.formsub.autor_id = this.author_id;
        const response = await axios.get(`/api/think?book_id=${this.$route.params.id}`);
        if (response.status == 200) {
          this.Think = response.data.data;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error.data);
      }
    },
    deleteRepasModal() {
      this.showDeleteRepasModal = !this.showDeleteRepasModal;
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.image = file;
    },
  },
};
</script>