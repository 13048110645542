<template>
  <div class="">
    

  </div>
</template>
  
<script>

export default {
  name: 'App',
  data() {
    
  
 
  },
};

</script>
  
<style scoped>


/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}</style>
  