<template>
  <div class="bg-white">
    <div class="custom-background h-80">
      <a href="#">
        <div class="relative">
          <div class="h-24"></div>
          <div v-if="this.auteur.profile_photo_path === null" class="lg:flex">
            <img
              class="mt-32 ml-44 w-32 h-32 rounded-full border-2 border-white"
              src="../assets/download.png"
              alt=""
            />
              <div class="flex">
              <div class="flex lg:mt-44 lg:ml-12 lg:text-white text-black">
                <spam class="mt-1">{{ this.auteur.name }}</spam>
              </div>
              <div class="flex lg:mt-44 lg:ml-6 lg:text-white text-black ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-9 h-9"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                  />
                </svg>
                <span class="mt-1">{{ total }} Livres</span>
              </div>
              <div class="flex lg:mt-44 lg:ml-12 lg:text-white text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-9 h-9"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <spam class="mt-1">{{ this.abonner.length }} Abonnée</spam>
              </div>
              <div class="h-12 rounded-lg lg:mt-44">
                <label class="inline-flex items-center">
                  <button
                    class="bg-blue-600 text-white flex px-6 py-2 ml-6 focus:outline-none poppins rounded-lg transform transition duration-300 hover:scale-105"
                  >
                    Abonnée
                  </button>
                </label>
              </div>
            </div>
          </div>
          <div v-else class="lg:flex">
            <img
              class="mt-32 ml-44 w-32 h-32 rounded-full border-2 border-white"
              :src="auteur.profile_photo_path"
              alt=""
            />
            <div class="flex">
              <div class="flex lg:mt-44 lg:ml-12 lg:text-white text-black">
                <spam class="mt-1">{{ this.auteur.name }}</spam>
              </div>
              <div class="flex lg:mt-44 lg:ml-6 lg:text-white text-black ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-9 h-9"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                  />
                </svg>
                <span class="mt-1">{{ total }} Livres</span>
              </div>
              <div class="flex lg:mt-44 lg:ml-12 lg:text-white text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-9 h-9"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <spam class="mt-1">{{ this.abonner.length }} Abonnée</spam>
              </div>
              <div class="h-12 rounded-lg lg:mt-44">
                <label class="inline-flex items-center">
                  <button
                    class="bg-blue-500 text-white flex px-6 py-2 ml-6 focus:outline-none poppins rounded-lg transform transition duration-300 hover:scale-105"
                  >
                    Abonnée
                  </button>
                </label>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="bg-white h-screen mt-24 lg:mt-0">
      <div class="lg:ml-44 lg:mr-44 lg:flex">
        <div class="mt-4 lg:w-1/3 mr-9 shadow-xl mt-12">
          <div class="ml-24 flex">
            <a href="#" class="mt-2"
              ><svg
                fill="currentColor"
                viewBox="0 0 24 24"
                class="h-6 w-6"
                aria-hidden="true"
              >
                <path
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                ></path></svg
            ></a>
            <a href="#" class="mt-2">
              <svg
                style="color: rgb(82, 224, 31)"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-whatsapp ml-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                  fill="#52e01f"
                ></path>
              </svg>
            </a>
            <a href="#" class="ml-2"
              ><svg
                fill="currentColor"
                viewBox="0 0 24 24"
                class="h-6 w-6 mt-2"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                ></path></svg
            ></a>
            <a href="#" class="ml-2"
              ><svg
                fill="currentColor"
                viewBox="0 0 24 24"
                class="h-6 w-6 mt-2"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                  clip-rule="evenodd"
                ></path></svg
            ></a>
            <a href="#" class="ml-2"
              ><svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="h-6 w-6 mt-2"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path
                  d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
                ></path></svg
            ></a>
          </div>

          <p class="mt-4 text-gray-700 text-left text-sm font-sans mx-4">
            {{ this.auteur.description }}
          </p>
        </div>
        <div class="mt-4 lg:w-2/3">
          <div class="mt-4 bg-black h-22 rounded-lg">
            <div class="flex text-white mt-6 lg:w-full h-12">
              <label class="inline-flex items-center w-1/2 mt-0">
                <div class="lg:w-full flex">
                  <span class="font-sans ml-2"
                    >Soutenir votre auteur pour ses histoires</span
                  >
                </div>
              </label>
              <label class="inline-flex items-center w-1/2">
                <router-link :to="`/don/${this.$route.params.id}`"
                  class="border border-green-500 ml-auto mr-4 text-white flex px-12 py-2 ml-4 focus:outline-none poppins rounded-lg transform transition duration-300 hover:scale-105"
                >
                  <spam class="ml-2">Soutenir</spam>
                </router-link>
              </label>
            </div>
          </div>
          <div class="mt-2 shadow-xl">
            <div class="flex flex-wrap -mx-4">
              <div
                v-for="(book, index) in books"
                :key="index"
                class=" w-1/2 px-4 mb-8"
              >
                <router-link
                  :to="`/romandetail/${book.id}/${book.slug}`"
                  class="block overflow-hidden transition-shadow duration-300 text-gray-700 rounded"
                >
                  <img
                    :src="book.banner_desktop"
                    :alt="book.banner_desktop"
                    class="object-cover h-64 rounded"
                  />
                  <div class="py-5">
                    <p class="text-sm font-bold leading-5">
                      {{ capitalizeFirstLetter(book.name) }}
                    </p>
                    <p
                      class="lg:mt-2 text-xs lg:font-semibold text-gray-600 uppercase"
                    >
                      {{ book.categories[0].category.name }}
                      <span v-if="book.categories[1]">{{
                        book.categories[1].category.name
                      }}</span>
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-9"></div>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import ModalComponent from "../components/ModalComponent .vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    ModalComponent,
  },
  data() {
    return {
      currentImage: 0,
      isDropdownOpen: false,
      books: [],
      auteur: [],
      total: "",
      profileID: "",
      abonner: [],
    };
  },
  created() {
    this.book();
  },
  methods: {
    capitalizeFirstLetter(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    async book() {
      try {
        const response = await axios.get(`/api/booksauteur?user_id=${this.$route.params.id}`);
        if (response.data) {
          this.books = response.data.data;
          this.auteur = this.books[0].user;
          this.total = this.books.length;
          this.profileID = this.auteur.id;
          this.subgt(this.profileID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async subgt(userId) {
      try {
        const response = await axios.get(`/api/subscribers?autor_id=${userId}`);

        if (response.data) {
          this.abonner = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
  },
};
</script>

<style scoped>
.custom-background {
  background-image: url("~@/assets/full-shot-woman-reading-tablet.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;

  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
