
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";


export const FETCH_USER = "fetchUser";
export const FETCH_USERS = "fetchUsers";
export const USER_ADD = "addUser";
export const USER_DELETE = "deleteUser";
export const USER_EDIT = "editUser";


export const FETCH_BOOKS = "fetchBooks";
export const FETCH_CATEGORIES = "fetchCategories";
export const FETCH_ONE_FETCH_CATEGORY = "fetchCategory";
export const FETCH_PROFILES = "fetchProfiles";
export const FETCH_ONE_FETCH_PROFILE = "fetchProfile";
