<template>
  <div class="bg-white">
    <div class="lg:mx-24 mx-6 mt-12">
      <h1 class="text-4xl font-bold text-left text-gray-700">Jeux Concours</h1>
    <div class=" ">
      <div
        class="grid grid-cols-2 lg:grid-cols-5 p-4 gap-4"
      >
        <div
          class="overflow-hidden transition-shadow duration-300 text-gray-700 rounded"
        >
          <router-link to="/enoncer"
            ><img
              src="../assets/cover.jpeg"
              class="object-cover w-full h-64 rounded"
              alt=""
          /></router-link>
          <div class="py-5">
            <p class="mb-2 text-xs flex font-semibold text-gray-600 text-left">
              Programme de mise à jour de septembre 2023 Plus d'exposition et
              plus de récompenses vous attendent !
            </p>

            <p class="text-sm font-bold text-left text-blue-500 mt-1">
              En cours
            </p>
            <div class="flex">
              <p class="mb-2 text-xs font-semibold text-gray-600 text-left">
                2023.05.10 - 2023.11.30
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 ml-36"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="overflow-hidden transition-shadow duration-300 text-gray-700 rounded"
        >
          <a href="/histoirejeux" aria-label="Article"
            ><img
              src="../assets/8.jpeg"
              class="object-cover w-full h-64 rounded"
              alt=""
          /></a>
          <div class="py-5">
            <p class="mb-2 text-xs flex font-semibold text-gray-600 text-left">
              Programme de mise à jour de septembre 2023 Plus d'exposition et
              plus de récompenses vous attendent !
            </p>

            <p class="text-sm font-bold text-left text-red-500 mt-1">
              Terminer
            </p>
            <div class="flex">
              <p class="mb-2 text-xs font-semibold text-gray-600 text-left">
                2023.05.10 - 2023.11.30
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 ml-36"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="overflow-hidden transition-shadow duration-300 rounded">
          <a href="/" aria-label="Article"
            ><img
              src="../assets/9.jpeg"
              class="object-cover w-full h-64 rounded"
              alt=""
          /></a>
          <div class="py-5">
            <p class="mb-2 text-xs font-semibold text-gray-600 uppercase">
              28 Dec 2020
            </p>
            <a
              href="/"
              aria-label="Article"
              class="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              <p class="text-2xl font-bold leading-5">Explore the beautiful</p>
            </a>
            <p class="mb-4 text-gray-700">Thiller</p>
          </div>
        </div>
        <div
          class="overflow-hidden transition-shadow duration-300 text-gray-700 rounded"
        >
          <a href="/" aria-label="Article"
            ><img
              src="../assets/10.jpeg"
              class="object-cover w-full h-64 rounded"
              alt=""
          /></a>
          <div class="py-5">
            <p class="mb-2 text-xs font-semibold text-gray-600 uppercase">
              13 Jul 2020
            </p>
            <a
              href="/"
              aria-label="Article"
              class="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              <p class="text-2xl font-bold leading-5">Diving to the deep</p>
            </a>
            <p class="mb-2 text-xs font-semibold text-gray-600 uppercase">
              Romance
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>


  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import ModalComponent from "../components/ModalComponent .vue";

export default {
  name: "App",
  components: {
    ModalComponent,
  },
  data() {
    return {
      images: [
        { src: require("@/assets/write-neopload-banner.jpeg") },
        { src: require("@/assets/background2.jpeg") },
        { src: require("@/assets/background1.jpeg") },
        { src: require("@/assets/background3.jpeg") },
      ],
      currentImage: 0,
      isDropdownOpen: false,
    };
  },
  methods: {
    startSlider() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 5000); // Défilement toutes les 5 secondes
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
  mounted() {
    this.startSlider();
    this.toggleDropdown();

    document.addEventListener("click", (event) => {
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    });
  },
};
</script>

<style scoped>
.custom-background {
  background-image: url("~@/assets/OF18NR0.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
