<script>
import axios from "axios";
import { loadScript } from "@paypal/paypal-js";

export default {
  name: "ContentComponentBuyPackage",
  components: {},
  data() {
    return {
      form: {
        amount: "",
        subscription: 0,
      },
      addfrom: {
        amount: 0,
        autor_id: 0,
        payment_id: "",
        status: "approved",
        payment_method: "",
      },
      prenom: "",
      planId: "subscription-direct",
      status: "none",
      method: "",
      processing: false,
      showModal: false,
      showButton: false,
      options: [
        {
          id: 1,
          label: "PayPal",
          image:
            "https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg",
        },
        {
          id: 2,
          label: "FeexPay",
          image: "https://feexpay.me/images/logo.png",
        },
        {
          id: 3,
          label: "pawaPay",
          image:
            "https://cdn.prod.website-files.com/62824591015aa314fd308df1/642ad1680ab5b8d2fde537ec_Logopawapay-2-p-500.png",
        },
      ],
    };
  },
  mounted() {
    this.profile();
  },

  methods: {
    initFeexPayButton() {
      this.showButton = true;
      if (!this.form.subscription) {
        alert("Veuillez saisir un montant.");
        return;
      }
      // eslint-disable-next-line no-undef
      FeexPayButton.init("render", {
        id: "66756fb94af31a555e782fe7",
        amount: this.form.subscription,
        token:
          "fp_SrVmGyGvalA9n4lUWeKUJufou0vK2EgVJ59dak3kpXaOPHrgRHpBWtsUJp5Hxs3n",
        callback: this.callbackFunction,
        callback_url: "https://your-callback-url.com",
        mode: "LIVE",
        custom_button: "true",
        id_custom_button: this.customButton ? "customButton" : null,
        custom_id: "45454",
        description: "Votre description",
        case: "MOBILE",
      });
    },
    callbackFunction() {
      this.AddBalance();
      console.log("Paiement réussi");
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.prenom = response.data.data.name;
          console.log(this.prenom);
        }
      } catch (error) {
        console.log(error.data);
      }
    },

    async AddBalance() {
      this.addfrom.amount = this.form.subscription;
      this.addfrom.autor_id = parseInt(this.$route.params.id, 10);
      this.addfrom.payment_method = "feexpay";
      this.addfrom.payment_id = 25451545;
      try {
        const response = await axios.post("/api/supported", this.addfrom);
        if (response.status == 201) {
          this.addform = {};
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async AddBalancePaypal() {
      const tauxDeChange = 525;

      this.addfrom.amount = this.form.subscription * tauxDeChange;
      this.addfrom.autor_id = parseInt(this.$route.params.id, 10);
      this.addfrom.payment_method = "paypal";
      this.addfrom.payment_id = 25451545;
      try {
        const response = await axios.post("/api/supported", this.addfrom);
        if (response.status == 201) {
          this.addform = {};
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    async loadPayPalButton() {
      loadScript({
        "client-id":
          "AV5hxEHcCwWejg52_h094hSclpVhcMIEEkd723OoE45AX1lt1vgfIeWQ7mXnZCgIoEIR8DO9h--aoyN7",
        currency: "USD",
      })
        .then((paypal) => {
          paypal
            .Buttons({
              createOrder: (data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: this.form.subscription,
                      },
                    },
                  ],
                });
              },
              onApprove: (data, actions) => {
                return actions.order.capture().then((details) => {
                  this.AddBalancePaypal();
                  console.log(details);
                });
              },
            })
            .render("#paypal-button-container");
        })
        .catch((err) => {
          console.error("Failed to load the PayPal JS SDK script", err);
        });
    },
  },
  // watch: {
  //   'form.subscription': function (newAmount) {
  //     console.log('Montant changé:', newAmount);
  //     if (newAmount) {
  //       this.showButton = true;
  //       this.$nextTick(() => {
  //         this.initFeexPayButton();
  //       });
  //     } else {
  //       this.showButton = false;
  //     }
  //   }
  // },
};
</script>

<template>
  <div class="bg-white sm:overflow-hidden z-10">
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-3/5 p-8 flex flex-col items-center">
        <ul class="w-full">
          <li
            class="my-4"
            @click="changePrice(form.subscription, 'subscription-direct')"
          >
            <input
              id="subscription-direct"
              type="radio"
              name="subscription"
              value="subscription-direct"
              class="hidden peer"
              required
            />
            <label
              for="subscription-direct"
              class="inline-flex justify-between items-center p-5 w-full text-gray-900 bg-white rounded-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div
                class="w-full p-4 flex flex-col md:flex-row justify-between items-center"
              >
                <p class="font-bold">Quel montant souhaitez-vous envoyer ?</p>
                <div class="flex flex-col">
                  <p class="mb-2">Entrer le montant ci-dessous</p>
                  <input
                    id="minuteUserWantGet"
                    v-model="form.subscription"
                    type="number"
                    class="mb-6 form-control block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-primary-base focus:outline-none"
                    min="100"
                    placeholder="100"
                  />
                </div>
                <svg
                  aria-hidden="true"
                  class="ml-3 w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div class="w-full lg:w-2/5 bg-white p-8 flex flex-col">
        <p class="mb-2 text-sm text-blue-500">
          Les paiements PayPal sont effectués en $, mais le reste est en XOF.
        </p>
        <p class="text-xl mb-6 text-gray-900">Récapitulatif du don</p>
        <div class="pb-6 border-b text-gray-900">
          <div class="flex justify-between">
            <p>Montant du don:</p>
            <p>{{ form.subscription }} USD ou XOF</p>
          </div>
          <div class="flex justify-between">
            <p>TVA:</p>
            <p>0 USD ou XOF</p>
          </div>
        </div>
        <div class="flex justify-between my-6">
          <p>Total</p>
          <p class="text-xl font-bold">{{ form.subscription }} USD ou XOF</p>
        </div>

        <h3 class="my-4 font-semibold text-gray-900">
          Choisissez votre solution de paiement*
        </h3>
        <div class="flex items-center">
          <div
            v-for="option in options"
            :key="option.id"
            class="flex items-center"
          >
            <label class="flex items-center px-4">
              <input v-model="method" type="radio" :value="option.label" />
              <img :src="option.image" class="w-28 ml-4" />
            </label>
          </div>
        </div>
        <div v-if="method === 'PayPal'">
          <button
            class="bg-blue-600 self-left lg:mr-96 mt-4 text-white font-bold p-2 px-4 rounded disabled:opacity-40"
            @click="loadPayPalButton"
          >
            Envoyer
          </button>
        </div>
        <div v-else-if="method === 'FeexPay'">
          <button
            class="bg-blue-600 self-left mt-4 text-white font-bold p-2 px-4 rounded disabled:opacity-40"
            @click="initFeexPayButton"
          >
            Envoyer
          </button>
        </div>
        <div class="mt-2" id="paypal-button-container"></div>
        <div v-if="showButton" id="render"></div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
