<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-44 bg-gray-800 flex items-center justify-center">
      <h1 class="font-bold text-4xl text-white">
        Politique de confidentialité
      </h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4">
      <h1 class="font-bold text-lg text-gray-700 mt-4">
        Veuillez lire attentivement cette politique de confidentialité (ci-après
        la « Politique de Confidentialité ») car celle-ci décrit la façon dont
        nous collectons, utilisons, divulguons, conservons et protégeons vos
        données personnelles. La présente Politique de Confidentialité
        s’applique à tout site Internet, application ou service faisant
        référence à la présente Politique de confidentialité. Lorsque vous nous
        fournissez ou que nous recueillons des données personnelles comme décrit
        au paragraphe 2 ci-après, vous nous autorisez à les recueillir, à les
        conserver et à les utiliser :
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        1.a) afin de répondre à nos obligations contractuelles à votre égard,
      </p>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        2.b) au titre de notre intérêt légitime à traiter vos données
        personnelles(par exemple à des fins de gestion administrative interne,
        d’analyse des données et d’analyses comparatives (consultez le
        paragraphe 3 ci-dessous pour obtenir plus d’informations), de marketing
        direct, de maintien de systèmes automatiques de sauvegarde ou de
        détection ou prévention de toute activité criminelle) ou
      </p>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        3.sur la base de votre consentement, que vous êtes en droit de retirer à
        tout moment, comme décrit dans la présente Politique de confidentialité.
        Cette déclaration de confidentialité peut vous concerner même si vous
        n’êtes pas l’un de nos clients et même si vous n’avez jamais utilisé un
        de nos sites Web, l’une de nos applications ou l’un de nos services.
        Certains de vos renseignements personnels peuvent être en notre
        possession car ils nous ont été fournis par un utilisateur d’un de nos
        sites Web, de nos applications ou de nos services. Brexit : dans cette
        déclaration de confidentialité, les références faites au « RGPD »
        englobent également tout type de « RGPD » du Bénin en vertu des lois
        numériques en vigueur.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        1. Qui sommes-nous ?
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Cette Politique de Confidentialité s’applique à tous les produits,
        applications et services proposés par SPEED-TECH , startup d’édition de
        logiciel immatriculée en République du Bénin sous le numéro
        0202011218767 et dont le siège social se trouve à l’adresse suivante :
        Godomey-Togoudo, Rue pharmacie le Jourdain, République du Bénin et par
        ses filiales, à l’exception de tout autre produit, application ou
        service régis par des politiques de confidentialité distinctes qui ne
        reprennent pas les dispositions de la présente Politique de
        confidentialité.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        2. Comment collectons-nous des informations ?
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Sous réserve des lois applicables, nous collectons des informations vous
        concernant ou concernant tout tiers dont vous nous fournissez les
        informations lorsque vous : • vous inscrivez pour utiliser nos sites
        Internet, applications ou services (y compris lors des essais gratuits)
        ; cela peut inclure votre nom (ou raison sociale, le cas échéant),
        adresse, adresse e-mail et numéro de téléphone. Nous pouvons également
        vous demander de nous fournir des informations complémentaires portant
        sur votre activité et sur vos préférences ; •passez une commande en
        utilisant nos sites Internet, applications ou services ; cela peut
        inclure votre nom (ou raison sociale), votre adresse et vos coordonnées
        de contact (y compris le numéro de téléphone et l’adresse e-mail) et de
        paiement ; utilisation de nos applications, qui peut inclure la collecte
        de métadonnées ; •remplissez des formulaires en ligne (y compris des
        demandes de rappel), prenez part à des sondages, postez des messages sur
        nos forums de discussion, mettez en ligne des blogs, participez à des
        concours ou à des tirages au sort, téléchargez des informations, telles
        que des livres blancs ou d’autres publications, ou participez à toute
        activité proposée sur les différents espaces interactifs présents sur
        notre site Internet ou sur notre application ou service ; •interagissez
        avec nous en utilisant des réseaux sociaux ; • nous fournissez vos
        coordonnées de contact lors de votre inscription ou de votre accès à
        tout site Internet, application ou service que nous mettons à
        disposition ou lorsque vous actualisez ces coordonnées ; et • nous
        contactez hors ligne, par exemple par téléphone, fax, SMS, e-mail ou
        courrier, par exemple. Nous recueillerons également vos données lorsque
        vous ne remplissez qu’une partie des champs à renseigner sur notre site
        et/ou sur d’autres formulaires en ligne et/ou lorsque vous abandonnez en
        cours de route cette saisie. Nous pouvons utiliser ces données pour vous
        contacter afin de vous rappeler de saisir les informations manquantes
        et/ou à des fins marketing. Nous pouvons également collecter des
        informations provenant de vos appareils électroniques (y compris de vos
        téléphones portables) et des applications utilisées par vous ou vos
        utilisateurs afin d’accéder à et d’utiliser l’un quelconque de nos sites
        Internet, applications ou services (à titre d’exemple, nous pouvons
        collecter le numéro d’identification et le type de l’appareil utilisé,
        des informations de géolocalisation et de connexion, telles que des
        statistiques portant sur les pages visitées, le trafic depuis et vers
        les sites, le lien URL de renvoi, les données publicitaires, votre
        adresse IP, votre historique de navigation et vos informations de
        journal Web). A ce titre, nous pouvons utiliser des cookies ou d’autres
        moyens similaires (comme décrit au paragraphe 11 ci-dessous). Sous
        réserve de la législation applicable, nous pouvons compléter les données
        personnelles que nous collectons avec des informations obtenues par des
        tiers qui sont autorisés à les partager ; à titre d’exemple, des
        informations provenant d’établissements de crédit, de prestataires en
        recherche d’informations ou de sources publiques (par exemple, aux fins
        de vérification préalable de la clientèle). Nous fournir les données
        d’un tiers Si vous nous fournissez des données personnelles concernant
        un tiers, il est de votre responsabilité de vous assurer de votre
        respect de la réglementation applicable en matière de protection des
        données à caractère personnel et notamment de vos obligations en matière
        d’obtention du consentement préalable des personnes dont vous nous
        fournissez les données personnelles. A ce titre, conformément à la
        réglementation applicable sur la protection des données, vous devez
        avoir prévenu la personne concernée et obtenu son accord exprès, ou que
        vous disposez d’une base légale pour nous fournir ses données
        personnelles et l’avoir informée de la façon dont nous collectons,
        utilisons, divulguons et conservons les données personnelles la
        concernant ou l’inviter à lire notre Politique de confidentialité.
      </p>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        6. Résiliation: Nous nous réservons le droit de résilier votre accès à
        l’application à tout moment sans préavis et sans responsabilité envers
        vous.
      </p>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        7.
        <span class="ml-4"
          >1.Loi applicable: Les présentes conditions d’utilisation sont régies
          et interprétées conformément aux lois de la juridiction dans laquelle
          l’application est hébergée.</span
        ><br />

        <span class="ml-4 mt-4"
          >2.Divisibilité: Si une disposition des présentes conditions
          d’utilisation est jugée illégale, nulle ou inapplicable, cette
          disposition sera considérée comme séparable des autres dispositions et
          n’affectera pas la validité et l’applicabilité des dispositions
          restantes.</span
        >
      </p>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        En utilisant cette application, vous acceptez les présentes conditions
        d’utilisation. Si vous n’acceptez pas ces conditions, vous ne devez pas
        utiliser cette application.
      </p>
    </div>

    <div class="h-6"></div>
  </div>
</template>
  
<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>
  
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
  