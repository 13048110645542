<template>
  <section class="py-20 bg-gray-50">
    <div
      class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16"
    >
      <div class="flex flex-wrap items-center -mx-3">
        <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
          <div class="w-full lg:max-w-md">
            <h2 class="mb-4 text-2xl font-bold">À propos de nous</h2>
            <h2
              class="mb-4 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500 text-left"
            >
              POURQUOI NOUS CHOISIR?
            </h2>

            <p
              class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6 text-left"
            >
              Afriplume est une plateforme de lecture et d'écriture africaine
              qui célèbre la diversité, la créativité et la richesse de la
              culture littéraire du continent.
            </p>
            <h2
              class="mb-4 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500 text-left"
            >
              Notre Mission
            </h2>

            <p
              class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6 text-left"
            >
              Chez Afriplume, notre mission est de fournir une plateforme
              inclusive où les écrivains africains et les amoureux de la
              littérature peuvent se connecter, partager leurs histoires, et
              découvrir de nouvelles perspectives. Nous croyons en
              l'autonomisation des voix africaines et en la promotion de la
              diversité des récits.
            </p>
            <ul>
              <li class="flex items-center py-2 space-x-4 xl:py-3">
                <svg
                  class="w-44 h-44 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                  ></path>
                </svg>
                <div class="mt-9">
                  <p class="font-bold text-gray-500 text-left">
                    Lecture Diversifiée
                  </p>
                  <p
                    class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6 text-left"
                  >
                    Explorez une variété de genres littéraires. Découvrez des
                    histoires passionnantes qui reflètent les nombreuses
                    facettes de la vie africaine.
                  </p>
                </div>
              </li>
              <li class="flex items-center py-2 space-x-4 xl:py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-44 h-44 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div class="mt-9">
                  <p class="font-bold text-gray-500 text-left">
                    Écriture Collaborative
                  </p>
                  <p
                    class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6 text-left"
                  >
                    Afriplume est la toile sur laquelle les auteurs peuvent
                    tisser leurs histoires. Publiez vos créations littéraires,
                    obtenez des retours de la communauté.
                  </p>
                </div>
              </li>
              <li class="flex items-center py-2 space-x-4 xl:py-3">
                <svg
                  class="w-44 h-44 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  ></path>
                </svg>
                <div class="mt-9">
                  <p class="font-bold text-gray-500 text-left">
                    Communauté Engagée
                  </p>
                  <p
                    class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6 text-left"
                  >
                    Rejoignez une communauté dynamique d'écrivains et de
                    lecteurs qui partagent leur amour pour la littérature
                    africaine.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
          <img
            class="mx-auto sm:max-w-sm lg:max-w-full"
            src="../assets/_fb368da7-465d-40c0-a902-38d808a3171c.jpeg"
            alt="feature image"
          />
        </div>
      </div>
    </div>
    <h2
      class="mb-4 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
    >
      Notre Engagement
    </h2>

    <p
      class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6 lg:ml-64 lg:mr-64 mx-6 lg:mx-0"
    >
      Chez Afriplume, nous nous engageons à soutenir et à promouvoir les talents
      littéraires africains. Nous travaillons en étroite collaboration avec des
      écrivains émergents et établis pour offrir des opportunités de publication
      et de visibilité. De plus, nous organisons des concours littéraires et des
      événements pour mettre en lumière les œuvres exceptionnelles.
    </p>
    <p
      class="mb-4 font-medium mt-6 tracking-tight text-gray-400 xl:mb-6 lg:ml-64 lg:mr-64 mx-6 lg:mx-0"
    >
      Explorez, écrivez, partagez, et découvrez l'univers riche et captivant de
      Afriplum.
    </p>
  </section>
  <section class="pt-12 pb-12 bg-red-50">
    <div
      class="container flex items-center justify-center p-6 mx-auto bg-white shadow-lg sm:p-12 md:w-1/2"
    >
      <div class="w-full">
        <h1
          class="mb-4 text-2xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500"
        >
          Votre avis 
        </h1>

        <div class="">
          <textarea
          v-model="addform.comment"
            name=""
            id=""
            rows="8"
            cols="30"
            class="w-full p-3 text-base border rounded-md focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-green-600"
            placeholder=" Votre message"
          ></textarea>
        </div>
        <button
          class="px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-green-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-green"
          @click="AddAvis()"
        >
          <span v-if="isLoading">
            <spiner />
          </span>
          <spam v-else> <span> Envoyer</span></spam>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "App",
  components: {
    spiner,
  },
  data() {
    return {
      addform: {
        comment: "",
        status:false,
        user_id: "",
      },

      userID: "",
      filter: "",
      isLoading: false,
    };
  },
  created() {
    this.profile();
    //this.book();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.userID = response.data.data.id;
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async AddAvis() {
      try {
        this.isLoading = true;
        this.addform.user_id = this.userID;
        const response = await axios.post(`/api/testimonies`, this.addform);
        if (response.status == 201) {
          this.isLoading = false;
          this.addform = {};
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci pour votre avis.",
            timeout: 5000,
          }).show();
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    startSlider() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 5000); // Défilement toutes les 5 secondes
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
