<template>
  <div class="bg-white sm:py-9 lg:px-8 flex">
    <div class="w-1/4"></div>
    <div class="text-center w-1/2 ml-4">
      <h1 class="text-3xl font-bold tracking-tight text-gray-700 sm:text-5xl">
        Conditions pour le concours
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left">
        Sorry, we couldn’t find the page you’re looking for. Le niveau de
        sécurité proposé par un hébergeur web doit faire partie des critères les
        plus importants lors du choix de ce service. Sur ce plan, Hostinger se
        démarque des concurrents du marché grâce à une panoplie complète
        d'outils et de services améliorant au maximum la sécurité.Tout
        l'équipement de refroidissement est à double alimentation indépendante
        avec un taux de disponibilité de 99,995 %. Outre des sauvegardes
        quotidiennes automatisées, les comptes sont isolés dans des conteneurs
        LVE et protégés en temps réel contre les attaques DDoS.L’hébergeur web
        Hostinger sécurise désormais tous les sites WordPress avec la protection
        Patchstack. Lorsque le programme détecte une menace, il applique
        instantanément un correctif sur le site web visé.Bienvenue sur IMMOLORD,
        votre plateforme en ligne dédiée à l'immobilier au BÉNIN. Parce que vous
        savez ce que vous recherchez et que nous comprenons à quel point le
        trouver peut-être riche en émotions diverses et complexes, nous avons
        conçu et déployé IMMOLORD. Notre plateforme vous offrira une expérience
        immersive, pratique et fiable dans la quête de biens immobiliers. Notre
        objectif est de connecter acquéreurs, locataires, propriétaires,
        promoteurs, vendeurs, et autres intermédiaires, de sorte que la demande
        et l’offre s’expriment avec clarté et précision et que les transactions
        immobilières au BÉNIN en soient facilitées. Que vous cherchiez une
        maison, un appartement, un terrain ou tout autre type de bien
        immobilier, ponctuellement ou dans la durée, notre site est conçu pour
        vous aider à trouver l'offre idéale, sans perte de temps et
        d'énergie.Notre vaste réseau de professionnels intervenant sur les
        chaînes de valeur de toutes sortes de transactions et de réalisations
        immobilières, nous confère un atout indéniable. Ainsi, nous collaborons
        étroitement avec des agences immobilières, des promoteurs réputés, des
        architectes, des constructeurs, des notaires, des avocats, des banques,
        des quincailleries, des experts du tourisme, etc. qui mettent à votre
        disposition leur expertise et leur connaissance approfondie et variée du
        contexte immobilier local. Grâce à la somme de savoirs et d’expériences
        de cet écosystème étendu de professionnels et à notre modèle
        opérationnel axé sur l’efficience, nous vous offrons un large éventail
        d'annonces immobilières actualisées régulièrement, pour que vous
        puissiez prendre des décisions éclairées. Nous nous efforçons de vous
        offrir en permanence un environnement en ligne sécurisé et convivial. La
        confidentialité de vos informations personnelles étant une priorité,
        nous avons mis en place des mesures de sécurité rigoureuses pour
        protéger vos données et garantir une expérience utilisateur en toute
        sérénité. Chez IMMOLORD, nous mettons l'accent sur votre satisfaction et
        restons donc à votre disposition pour recueillir vos plaintes et
        suggestions, et les traiter de façon optimale. Explorez notre site,
        découvrez nos annonces et démarrez votre quête immobilière dès
        aujourd'hui sur IMMOLORD. Votre partenaire de confiance pour trouver au
        BÉNIN, le bien immobilier à votre satisfaction.
      </p>
      <div class="flex ml-9 mt-2">
        <router-link to="/dash">
          <button
            class="text-gray-700 rounded-lg bg-yellow-500 px-8 py-2 text-xl mt-2"
          >
            Participer
          </button>
        </router-link>
      </div>
    </div>
    <div class="w-1/4 mt-44">
      <p class="text-gray-700 text-2xl">Partager</p>
      <div class="ml-36">
        <a href="#"
          ><svg
            fill="currentColor"
            viewBox="0 0 24 24"
            class="h-12 w-12"
            aria-hidden="true"
          >
            <path
              d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
            ></path></svg
        ></a>
        <a href="#"
          ><svg
            fill="currentColor"
            viewBox="0 0 24 24"
            class="h-12 w-12 mt-2"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clip-rule="evenodd"
            ></path></svg
        ></a>
        <a href="#" class="mt-2">
          <svg
            style="color: rgb(82, 224, 31)"
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            fill="currentColor"
            class="bi bi-whatsapp mt-2 ml-2"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
              fill="#52e01f"
            ></path>
          </svg>
        </a>
        <a href="#"
          ><svg
            fill="currentColor"
            viewBox="0 0 24 24"
            class="h-12 w-12 mt-2"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
              clip-rule="evenodd"
            ></path></svg
        ></a>
        <a href="#"
          ><svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="h-12 w-12 mt-2"
            viewBox="0 0 24 24"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path
              d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
            ></path></svg
        ></a>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "App",

  data() {
    return {};
  },
};
</script>    
<style scoped>
.custom-background {
  background-image: url("~@/assets/background.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}

/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
      